import Sortable from "sortablejs";
import * as bootstrap from "bootstrap";
import "javascripts/best_in_place";

$('<style>')
    .text(`
        @keyframes spin {
            from { transform: rotate(0deg); }
            to { transform: rotate(360deg); }
        }
        .spin {
            animation: spin 1s linear infinite;
            display: inline-block;
        }
    `)
    .appendTo('head');

document.addEventListener("turbolinks:load", function () {

    $('table td, table th').each(function () {
        // Remove % and convert to a float
        var value = parseFloat($(this).text().replace('%', ''));
        if (value < 0) {
            $(this).css('color', 'red');
        }
    });

    window.best_in_place_utility = best_in_place_utility;
    window.initializeAssetDropZone = initializeAssetDropZone;

    best_in_place_utility();
    initializeAssetDropZone();
    initializeInvestmentDropZone();
    initializeNavDropZone();

    function best_in_place_utility(){
        $(".best_in_place").best_in_place();

        $('.best_in_place').bind("ajax:success", function () {
            var toast = "<div class='toast align-items-center text-white bg-success border-0' role='alert' aria-live='assertive' aria-atomic='true'><div class='d-flex'><div class='toast-body'>Updated</div><button type='button' class='btn-close btn-close-white me-2 m-auto' data-bs-dismiss='toast' aria-label='Close'></button></div></div>"
            $("#toast-container").append(toast)
            return new bootstrap.Toast($("#toast-container").children().last()).show();
        });

        $('.best_in_place_reload').bind("ajax:success", function () {
            $('#overlay').fadeIn();
            window.location.reload();
        });

        $(".best_in_place.badge.rounded-pill").click(function () {
            $(this).toggleClass("bg-success");
            $(this).toggleClass("bg-danger");
        })

        $('.best_in_place_reload_no_params').bind("ajax:success", function () {
            $('#overlay').fadeIn();
            // Get the current URL
            var currentUrl = window.location.href;

            // Remove the parameters from the URL
            var urlWithoutParams = currentUrl.split('?')[0];

            // Reload the page with the modified URL
            window.location.href = urlWithoutParams;
        });
    }



    function initializeInvestmentDropZone(){
        document.addEventListener('dragover', function (e) {
            if (e.target.classList.contains('drop-zone')) {
                e.preventDefault();
                e.target.style.backgroundColor = '#999999';
            }
        });

        document.addEventListener('dragleave', function (e) {
            if (e.target.classList.contains('drop-zone')) {
                e.preventDefault();
                e.target.style.backgroundColor = '#0E0000';
            }
        });

        document.addEventListener('drop', function (e) {
            if (e.target.classList.contains('investment-drop-zone')) {
                e.preventDefault();
                e.target.style.backgroundColor = '#0E0000';
                var files = e.dataTransfer.files;
                if (files.length > 1) {
                    alert('Only one file can be uploaded at a time.');
                    return;
                }
                var idParts = e.target.id.split('-');
                var investmentId = idParts.slice(2).join('-');
                var fileInput = document.getElementById('file-upload-' + investmentId);
                fileInput.files = files;
                uploadInvestmentFile(files[0], investmentId);
            }
        });

        function uploadInvestmentFile(file, investmentId) {
            var csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

            var url = '/investments/' + investmentId + '/attach_document';
            var xhr = new XMLHttpRequest();
            var formData = new FormData();
            xhr.open('POST', url, true);
            xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
            xhr.setRequestHeader('X-CSRF-Token', csrfToken);

            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4 && xhr.status === 200) {
                    eval(xhr.responseText);
                }
            };

            formData.append('file', file);
            formData.append('investment_id', investmentId);
            xhr.send(formData);
        }
    }
    function initializeNavDropZone(){

        document.addEventListener('dragover', function (e) {
            if (e.target.classList.contains('drop-zone')) {
                e.preventDefault();
                e.target.style.backgroundColor = '#999999';
            }
        });

        document.addEventListener('dragleave', function (e) {
            if (e.target.classList.contains('drop-zone')) {
                e.preventDefault();
                e.target.style.backgroundColor = '#0E0000';
            }
        });

        document.addEventListener('drop', function (e) {
            if (e.target.classList.contains('price-drop-zone')) {
                e.preventDefault();
                e.target.style.backgroundColor = '#0E0000';
                var files = e.dataTransfer.files;
                if (files.length > 1) {
                    alert('Only one file can be uploaded at a time.');
                    return;
                }
                var idParts = e.target.id.split('-');
                var priceId = idParts.slice(2).join('-');
                console.log('priceId:', priceId); // Debug line
                var fileInput = document.getElementById('file-upload-' + priceId);
                console.log('fileInput:', fileInput); // Debug line
                fileInput.files = files;
                uploadNAVFile(files[0], priceId);
            }
        });

        function uploadNAVFile(file, priceId) {
            var csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

            var url = '/prices/' + priceId + '/attach_document';
            var xhr = new XMLHttpRequest();
            var formData = new FormData();
            xhr.open('POST', url, true);
            xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
            xhr.setRequestHeader('X-CSRF-Token', csrfToken);

            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4 && xhr.status === 200) {
                    eval(xhr.responseText);
                }
            };

            formData.append('file', file);
            formData.append('price_id', priceId); // Add the priceId to the FormData
            xhr.send(formData);
        }
    }

    function initializeAssetDropZone() {

        var dropZone = document.querySelector('[id$="-dropzone"]');

        if (dropZone) {
            var assetId = dropZone.getAttribute('data-asset-id');
            var assetType = dropZone.getAttribute('data-asset-type');

            // Remove any existing event listeners by cloning the node
            dropZone.replaceWith(dropZone.cloneNode(true));
            dropZone = document.querySelector('[id$="-dropzone"]');

            // Highlight drop zone when file is dragged over it
            dropZone.addEventListener('dragover', function (e) {
                e.preventDefault();
                this.style.backgroundColor = 'rgba(56, 56, 56, 0.5)';
            });

            // Reset background when file is dragged out
            dropZone.addEventListener('dragleave', function (e) {
                this.style.backgroundColor = 'rgba(0, 0, 0, 0.8)';
            });

            // Handle file drop
            dropZone.addEventListener('drop', function (e) {
                e.preventDefault();
                this.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';

                var files = e.dataTransfer.files;
                var formData = new FormData();

                for (var i = 0; i < files.length; i++) {
                    formData.append('documents[]', files[i], files[i].name);
                }

                formData.append('documentable_id', assetId);
                formData.append('documentable_type', assetType);

                // Get CSRF token
                var csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

                fetch('/documents', {
                    method: 'POST',
                    headers: {
                        'X-CSRF-Token': csrfToken,
                        'Accept': 'text/javascript'
                    },
                    body: formData,
                    credentials: 'same-origin'
                })
                    .then(response => response.text())
                    .then(data => eval(data))
                    .catch(error => console.error(error));
            });
        }
    }

    var dropdownMenu = $('.dropdown-menu');

    dropdownMenu.on('scroll', function () {
        var element = this;
        var scrollHeight = element.scrollHeight;
        var scrollTop = element.scrollTop;
        var clientHeight = element.clientHeight;

        console.log('scrollHeight:', scrollHeight);
        console.log('scrollTop:', scrollTop);
        console.log('clientHeight:', clientHeight);

        if (scrollTop + clientHeight >= scrollHeight) {
            console.log('Scrolled to the bottom');
            // User has scrolled to the bottom
            // Prevent further scrolling of parent or body element
            event.preventDefault();
        }
    });


    function fnBlink() {
        $(".blink").fadeOut(1000);
        $(".blink").fadeIn(1000);
    }

    setInterval(fnBlink, 0);

    autocompleteAssets();

    function autocompleteAssets() {
        var input = $('*[data-behavior="autocomplete-asset"]');

        var options = {
            url: function (phrase) {
                return "/search_assets.json?q=" + phrase;
            },
            theme: $('body').data('theme'),
            adjustWidth: "false",
            getValue: "asset_name",
            placeholder: "Start typing the asset name",
            template: {
                type: "custom",
                method: function (value, item) {
                    return value + "<i class='text-muted'> | " + item.type + " (" + item.currency_symbol + ")</i>";
                }
            },
            list: {
                maxNumberOfElements: 20,
                onChooseEvent: function () {
                    var asset_id = $("#investment_asset_name").getSelectedItemData().asset_id;
                    $("#investment_asset_id").val(asset_id).trigger("change");
                    $("#investment_asset_name").attr("value", $("#investment_asset_name").val()).trigger("change");

                    var asset_id_for_option = $("#option_underlying_asset_name").getSelectedItemData().asset_id;
                    $("#option_underlying_asset_id").val(asset_id_for_option).trigger("change");
                    $("#option_underlying_asset_name").attr("value", $("#option_underlying_asset_name").val()).trigger("change");

                    var asset_id_from_bank = $("#investment_asset_name_from_bank").getSelectedItemData().asset_id;
                    $("#investment_asset_id_from_bank").val(asset_id_from_bank).trigger("change");
                    $("#investment_asset_name_from_bank").attr("value", $("#investment_asset_name_from_bank").val()).trigger("change");
                }
            }
        };

        input.easyAutocomplete(options);
    }

    autocompleteContacts();

    function autocompleteContacts() {
        var input = $('*[data-behavior="autocomplete-contact"]');

        var options = {
            url: function (phrase) {
                return "/search_contacts.json?q=" + phrase;
            },
            theme: $('body').data('theme'),
            adjustWidth: "false",
            getValue: "contact_label",
            placeholder: "Start typing the contact name",
            template: {
                type: "custom",
                method: function (value, item) {
                    if (item.organisation) {
                        var org = " – " + item.organisation
                    } else {
                        var org = ""
                    }
                    if (item.title) {
                        var title = item.title
                    } else {
                        var title = ""
                    }
                    if (item.title || item.organisation) {
                        var sep = " | "
                    } else {
                        var sep = ""
                    }
                    return value + "<i class='text-muted'>" + sep + title + org + "</i>";
                }
            },
            list: {
                maxNumberOfElements: 20,
                onChooseEvent: function () {
                    var contact_id = $("#deal_contact_label").getSelectedItemData().contact_id;
                    $("#deal_contact_id").val(contact_id).trigger("change");
                    $("#deal_contact_label").attr("value", $("#deal_contact_label").val()).trigger("change");
                }
            }
        };

        input.easyAutocomplete(options);
    }

    autocompleteUnderlyings();

    function autocompleteUnderlyings() {
        var input = $('*[data-behavior="autocomplete-underlying"]');

        var options = {
            url: function (phrase) {
                return "/search_assets.json?q=" + phrase;
            },
            theme: $('body').data('theme'),
            adjustWidth: "false",
            getValue: "asset_name",
            placeholder: "Underlying name",
            template: {
                type: "custom",
                method: function (value, item) {
                    return value + "<i class='text-muted'> | " + item.type + "</i>";
                }
            },
            list: {
                maxNumberOfElements: 20,
                onChooseEvent: function () {
                    var asset_id = $("#underlying_name").getSelectedItemData().asset_id;
                    $("#underlying_id").val(asset_id).trigger("change");
                    $("#underlying_name").attr("value", $("#underlying_name").val()).trigger("change");
                }
            }
        };

        input.easyAutocomplete(options);
    }

    $(document).on('click', '.auto-discount-calc', function(e) {
        e.preventDefault();
        const $button = $(this);
        const $icon = $button.find('i');
        const assetId = $button.data('asset-id');
        
        // Add spinning animation
        $icon.addClass('spin');
        $button.prop('disabled', true);
        
        $.ajax({
            url: `/assets/${assetId}/calculate_auto_discount`,
            method: 'PUT',
            dataType: 'json',
            headers: {
                'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
            },
            success: function(data) {
                console.log('Received discount:', data);
                
                // Try to update the DOM immediately
                const $discountElement = $('.best_in_place[data-attribute="discount"]');
                if ($discountElement.length) {
                    $discountElement.text(`${data.discount}%`);
                    if ($discountElement.data('bestInPlaceEditor')) {
                        $discountElement.data('bestInPlaceEditor').update();
                    }
                }

                // Force a Turbolinks visit to refresh the page
                setTimeout(() => {
                    window.location.reload(true);
                    if (typeof Turbolinks !== 'undefined') {
                        Turbolinks.visit(window.location.toString(), { action: "replace" });
                    }
                }, 100);
            },
            error: function(xhr, status, error) {
                console.error('Error calculating auto discount:', error);
                // Remove spinning animation
                $icon.removeClass('spin');
                $button.prop('disabled', false);
            }
        });
    });

});